import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/s-jakym-sportem-nejrychleji-zhubnu.jpg'
import styles from './post-grid.module.css'

const JakymSportemNejrychlejiZhubnu = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'S jakým sportem nejrychleji zhubnu? | NutritionPro'}
        description={
          'To, jestli budete hubnout, ovlivňuje spousta faktorů. Vždy jde o celek. O celkový životní styl a to, jak se o své tělo a mysl staráte. Nejdůležitějším faktorem je strava. Je důležité znát váš optimální energetický příjem, jelikož se při hubnutí musíte dostat do energetického deficitu. Také je důležité vybírat kvalitní potraviny, vědět, jaké množství živin byste měli přijmout a též byste měli být opatrní s tepelnou úpravou. To je ovšem téma pro jiný článek. Mimo stravy je také důležitý pohyb, který vám může hubnutí velmi usnadnit. Díky cvičení spálíte kalorie, které vám pomohou dostat se do energetického deficitu. Také díky němu budujete svalovou hmotu, která je metabolicky aktivní a pomáhá vám spalovat energii, i když zrovna nic neděláte. Sportování má vliv na vaši kondici, metabolismus a celkově fyzické zdraví. Má ale vliv i na psychický stav a může vám jednoduše pomoci se lépe cítit. S psychickým stavem se pojí také hubnutí, takže jak vidíte, opravdu jde vše ruku v ruce.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/s-jakym-sportem-nejrychleji-zhubnu">
            S jakým sportem nejrychleji zhubnu?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="S jakým sportem nejrychleji zhubnu?"
              date="25.03.2023"
            />
            <div>
              <h5 className={styles.postTitle}>Sport a hubnutí</h5>
              <p className={styles.postText}>
                To, jestli budete hubnout, ovlivňuje <b>spousta faktorů.</b>{' '}
                Vždy jde o celek. O <b>celkový životní styl</b> a to, jak se o
                své tělo a mysl staráte. Nejdůležitějším faktorem je{' '}
                <b>strava.</b> Je důležité znát váš optimální energetický
                příjem, jelikož se při hubnutí musíte dostat do{' '}
                <b>energetického deficitu.</b> Také je důležité vybírat kvalitní
                potraviny, vědět, jaké množství živin byste měli přijmout a též
                byste měli být opatrní s tepelnou úpravou. To je ovšem téma pro
                jiný článek. Mimo stravy je také důležitý <b>pohyb,</b> který
                vám může hubnutí velmi usnadnit. Díky cvičení{' '}
                <b>spálíte kalorie,</b> které vám pomohou dostat se do
                energetického deficitu. Také díky němu{' '}
                <b>budujete svalovou hmotu,</b> která je metabolicky aktivní a{' '}
                <b>pomáhá vám spalovat energii,</b> i když zrovna nic neděláte.
                Sportování má vliv na vaši kondici, metabolismus a celkově{' '}
                <b>fyzické zdraví.</b> Má ale vliv i na <b>psychický stav</b> a
                může vám jednoduše pomoci se lépe cítit. S psychickým stavem se
                pojí také hubnutí, takže jak vidíte, opravdu jde vše{' '}
                <b>ruku v ruce.</b>
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="sportovní dívka"
              />

              <p className={styles.postText}>
                <b>TIP:</b> Jak se dostat do formy díky stravování, se dozvíte v{' '}
                <a
                  href="https://nutritionpro.cz/blog/dostante-se-do-formy/"
                  target="_blank"
                >
                  tomto článku.
                </a>
              </p>

              <h5 className={styles.postTitle}>Jaký sport zvolit?</h5>
              <p className={styles.postText}>
                Teď už víte, proč je pohyb pro hubnutí důležitý. Jaký ale
                zvolit, aby vám hubnutí šlo co nejrychleji? Odpověď je velmi
                prostá. <b>Zvolte ten, co vás baví.</b> Když budete dělat to, co
                vás baví, bude to pro vás <b>udržitelné.</b> A to je vaším
                cílem. Nemá smysl se týrat v posilovně, pokud vás posilování
                nebaví. Také nemá smysl chodit běhat, pokud se celou dobu
                modlíte, aby to už skončilo. Začátky nebývají nikdy jednoduché,
                pokud už se ale nějaký čas snažíte a stejně je pro vás pohyb
                nepříjemný, <b>začněte dělat něco jiného.</b> Můžete vyzkoušet
                úplně cokoliv, co vám aktuální možnosti dovolí. Nechte si
                poradit od odborníka a <b>naučte se správné technice,</b> abyste
                z dané aktivity vytěžili co nejvíce, neublížili si a mohli se
                věnovat naplno tomu, co vás baví dělat!
              </p>

              <p className={styles.postText}>
                <b>Kardio</b> cvičením <b>zvyšujete tepovou frekvenci,</b> a tak{' '}
                <b>maximalizujete spalování tuků.</b> Posilujete především
                kardiovaskulární systém. Za kardio je považován například běh,
                rychlá chůze, plavání, bruslení a mnoho dalšího.
              </p>

              <p className={styles.postText}>
                Oproti tomu <b>silový trénink</b> využívá nějakou <b>zátěž</b> a
                tepová frekvence je nižší. Součástí tréninku jsou pravidelné
                pauzy. Cílem takových tréninků je{' '}
                <b>nabrat svalovou hmotu, zvýšit sílu a posílit kosti.</b>
              </p>

              <p className={styles.postText}>
                Dobré je pravidelně zařadit <b>oba typy cvičení,</b> jelikož
                každý z nich přináší určité benefity a oba dva vám{' '}
                <b>mohou pomoci shodit přebytečná kila.</b>
              </p>

              <h5 className={styles.postTitle}>Závěrem</h5>
              <p className={styles.postText}>
                Jak jste asi pochopili,{' '}
                <b>
                  neexistuje jeden jediný sport, který by vám nejefektivněji
                  pomohl zhubnout.
                </b>{' '}
                Ideální je kombinace kardia a silového tréninku, které buď
                můžete dělat najednou, nebo aktivity pravidelně střídat.
                Důležité je ovšem to, jestli vás aktivita baví. To je základ
                úspěchu.
              </p>

              <p className={styles.postText}>
                Ptáte se, zdali je možné zhubnout i ve sportu? Dozvíte se v{' '}
                <a
                  href="https://nutritionpro.cz/blog/jidelnicek-hubnuti-bez-sportu/"
                  target="_blank"
                >
                  tomto článku.
                </a>
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default JakymSportemNejrychlejiZhubnu
